import { consignmentConstants, socketConstants, tripConstants } from '@constants';

import { updateConsignmentInList } from '@utils/consignmentUtils';
import { updateTripActionInList, updateTripInList } from '@utils/tripUtils';

const initialState = {
  isFetchingConsignment: false,
  formConsignment: null,
  consignment: null,

  isFetchingConsignments: false,
  isFetchingConsignmentsFrom: 0,
  consignmentsTotalResults: 0,
  consignmentsLoaded: 0,
  consignments: [],

  trips: [],
  trip: null,
};

export default function consignment(state = initialState, action) {
  switch (action.type) {
    case consignmentConstants.CONSIGNMENT_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case consignmentConstants.GET_CONSIGNMENT_STARTED:
      return { ...state, isFetchingConsignment: true };
    case consignmentConstants.GET_CONSIGNMENT_FAILURE:
      return {
        ...state,
        isFetchingConsignment: false,
        error: action.message || '',
      };
    case consignmentConstants.GET_CONSIGNMENT_SUCCESS:
      return {
        ...state,
        isFetchingConsignment: false,
        consignment: action.consignment,
        trips: action.consignment.trips,
      };

    case consignmentConstants.GET_CONSIGNMENTS_STARTED:
      return {
        ...state,
        isFetchingConsignments: true,
        isFetchingConsignmentsFrom: action.from || 0,
      };
    case consignmentConstants.GET_CONSIGNMENTS_FAILURE:
      return {
        ...state,
        isFetchingConsignments: false,
        error: action.message || '',
      };
    case consignmentConstants.GET_CONSIGNMENTS_SUCCESS:
      return {
        ...state,
        isFetchingConsignments: false,
        consignments:
          action.from > 0 ? [...state.consignments, ...action.consignments] : action.consignments,
        consignmentsLoaded:
          action.from > 0
            ? state.consignments.length + action.consignments.length
            : action.consignments.length,
        consignmentsTotalResults: action.totalResults || 0,
      };

    case consignmentConstants.SEARCH_CONSIGNMENTS_STARTED:
      return {
        ...state,
        isFetchingConsignments: true,
        isFetchingConsignmentsFrom: action.from || 0,
      };
    case consignmentConstants.SEARCH_CONSIGNMENTS_FAILURE:
      return {
        ...state,
        isFetchingConsignments: false,
        error: action.message || '',
      };
    case consignmentConstants.SEARCH_CONSIGNMENTS_SUCCESS:
      return {
        ...state,
        isFetchingConsignments: false,
        consignments:
          action.from > 0 ? [...state.consignments, ...action.consignments] : action.consignments,
        consignmentsLoaded:
          action.from > 0
            ? state.consignments.length + action.consignments.length
            : action.consignments.length,
        consignmentsTotalResults: action.totalResults || 0,
      };

    case consignmentConstants.CREATING_CONSIGNMENT_STARTED:
      return { ...state, isFetchingConsignment: true };
    case consignmentConstants.CREATING_CONSIGNMENT_FAILURE:
      return {
        ...state,
        isFetchingConsignment: false,
        error: action.message || '',
      };
    case consignmentConstants.CREATING_CONSIGNMENT_SUCCESS:
      return {
        ...state,
        isFetchingConsignment: false,
        consignment: action.consignment,
        consignments: state.consignments
          .map((consignment) => consignment.id)
          .includes(action.consignment.id)
          ? updateConsignmentInList(state, action.consignment)
          : [...state.consignments, action.consignment],
      };

    case consignmentConstants.UPDATING_CONSIGNMENT_STARTED:
      return { ...state, isFetchingConsignment: true };
    case consignmentConstants.UPDATING_CONSIGNMENT_FAILURE:
      return {
        ...state,
        isFetchingConsignment: false,
        error: action.message || '',
      };
    case consignmentConstants.UPDATING_CONSIGNMENT_SUCCESS:
      return {
        ...state,
        isFetchingConsignment: false,
        consignment: action.consignment,
        consignments: [...state.consignments].map((consignment) => {
          if (action.consignment.id === consignment.id) {
            return action.consignment;
          }
          return consignment;
        }),
      };

    case consignmentConstants.DELETE_CONSIGNMENT_STARTED:
      return { ...state, isFetchingConsignment: true };
    case consignmentConstants.DELETE_CONSIGNMENT_FAILURE:
      return {
        ...state,
        isFetchingConsignment: false,
        message: action.message || '',
      };
    case consignmentConstants.DELETE_CONSIGNMENT_SUCCESS:
      return {
        ...state,
        isFetchingConsignment: false,
        consignment: action.consignment,
        consignments: [...state.consignments].filter(
          (consignment) => action.consignment.id !== consignment.id
        ),
      };

    case tripConstants.DELETE_TRIP_SUCCESS:
      return {
        ...state,
        isFetchingTrip: false,
        trip: action.trip,
        trips: [...state.trips].filter((trip) => action.trip.id !== trip.id),
      };

    case tripConstants.CONFIRM_TRIP_STARTED:
      return {
        ...state,
        isFetchingTrip: true,
        trips: updateTripInList(state, { isLoading: true, ...action.trip }),
      };
    case tripConstants.CONFIRM_TRIP_FAILURE:
      return {
        ...state,
        isFetchingTrip: false,
        trips: updateTripInList(state, { isLoading: false, ...action.trip }),
        message: action.message || '',
      };
    case tripConstants.CONFIRM_TRIP_SUCCESS:
      return {
        ...state,
        isFetchingTrip: false,
        trip: action.trip,
        trips: updateTripInList(state, action.trip),
      };

    case tripConstants.START_TRIP_STARTED:
      return {
        ...state,
        isFetchingTrip: true,
        trips: updateTripInList(state, { isLoading: true, ...action.trip }),
      };
    case tripConstants.START_TRIP_FAILURE:
      return {
        ...state,
        isFetchingTrip: false,
        trips: updateTripInList(state, { isLoading: false, ...action.trip }),
        message: action.message || '',
      };
    case tripConstants.START_TRIP_SUCCESS:
      return {
        ...state,
        isFetchingTrip: false,
        trip: action.trip,
        trips: updateTripInList(state, action.trip),
      };

    case tripConstants.CANCEL_TRIP_STARTED:
      return {
        ...state,
        isFetchingTrip: true,
        trips: updateTripInList(state, { isLoading: true, ...action.trip }),
      };
    case tripConstants.CANCEL_TRIP_FAILURE:
      return {
        ...state,
        isFetchingTrip: false,
        trips: updateTripInList(state, { isLoading: false, ...action.trip }),
        message: action.message || '',
      };
    case tripConstants.CANCEL_TRIP_SUCCESS:
      return {
        ...state,
        isFetchingTrip: false,
        trip: action.trip,
        trips: updateTripInList(state, action.trip),
      };

    case tripConstants.FINISH_TRIP_STARTED:
      return {
        ...state,
        isFetchingTrip: true,
        trips: updateTripInList(state, { isLoading: true, ...action.trip }),
      };
    case tripConstants.FINISH_TRIP_FAILURE:
      return {
        ...state,
        isFetchingTrip: false,
        trips: updateTripInList(state, { isLoading: false, ...action.trip }),
        message: action.message || '',
      };
    case tripConstants.FINISH_TRIP_SUCCESS:
      return {
        ...state,
        isFetchingTrip: false,
        trip: action.trip,
        trips: updateTripInList(state, action.trip),
      };

    case tripConstants.UPDATE_TRIP_ACTION_STARTED:
      return {
        ...state,
        trips: updateTripActionInList(
          state,
          { ...action.trip },
          { isLoading: true, ...action.action }
        ),
      };
    case tripConstants.UPDATE_TRIP_ACTION_SUCCESS:
    case tripConstants.UPDATE_TRIP_ACTION_FAILURE:
      return {
        ...state,
        trips: updateTripActionInList(
          state,
          { ...action.trip },
          { isLoading: false, ...action.action }
        ),
      };

    case socketConstants.NEW_CONSIGNMENT:
      console.log('should not filter out the consignment', action.isWithinViewFilters, action);
      return {
        ...state,
        consignment:
          action.value?.id === state.consignment?.id ? { ...action.value } : state.consignment,
        consignments: action.isWithinViewFilters
          ? [...state.consignments].map((consignment) => consignment.id).includes(action.value?.id)
            ? updateConsignmentInList(state, action.value)
            : [...state.consignments, action.value]
          : [...state.consignments].filter((consignment) => action.value?.id !== consignment.id),
      };
    default:
      return state;
  }
}
